import * as React from "react"
import Layout from "../components/Layout"
import {GatsbyImage, getImage, IGatsbyImageData, StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import {graphql, Link} from "gatsby";
import Carousel from "react-multi-carousel";
import {Parallax} from "react-scroll-parallax";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
// SVGS
import HomeLine from '../images/inline/home_line.inline.svg'

interface Props {
    data: {
        allMarkdownRemark: any
    }
}

const IndexPage = ({data}: Props) => {

    let tequilas = data.allMarkdownRemark.edges as any
    const responsive = {
        desktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 1200},
            items: 3,
        },
        mobile: {
            // the naming can be any, depends on you.
            breakpoint: {max: 1200, min: 0},
            items: 1,
        }
    };

    const {t} = useTranslation();

    return (
        <Layout>
            <title>Home | Santaleza</title>

            <section className="main-section">
                <Parallax speed={-20} translateX={[0, 0]} translateY={[-50, 0]} className="maintext">
                    <p>
                        <span className="subtt santalezatext">Santaleza</span>
                        <i className="linea"></i>
                        <span className="tequila">
                            <Trans>The tequila</Trans>
                        </span>
                    </p>
                    <p>
                        <span className="hecho">
                            <Trans>Home</Trans>
                        </span>
                    </p>
                    <Link to="/our-essence" className="boton tbutton"><Trans>Discover button</Trans></Link>
                </Parallax>
                <Parallax speed={50} translateY={[-50, 20]} className="scrolldown">
                    <p className="parrafo">Scroll</p>
                    <p className="parrafo">Down</p>
                    <StaticImage className="img-vector" src="../images/down-vector.svg" alt="flecha_hacia_abajo"/>
                </Parallax>
                {/*
                <Parallax speed={-20} translateX={[0, 0]} translateY={[15, 70]} className="postal">
                    <StaticImage className={'img'} src="../images/home/postal.png" alt="postal"/>
                </Parallax>
                 */}
            </section>

            <section className="second-section">
                <div className="bloque1">
                    <div className={"decoration-left"}>
                        <Parallax speed={50} translateY={[-40, 0]}>
                            <HomeLine/>
                        </Parallax>
                    </div>
                    <div className="cactus-bloq1">
                        <Parallax speed={50} translateY={[-10, 10]}>
                            <StaticImage src="../images/home/botella_santaleza.jpg" alt="botella_santaleza"/>
                            <StaticImage className="sello-sta" src="../images/home/sello.svg" alt="sello santaleza"/>
                        </Parallax>
                    </div>
                    <div className="pbloq1">
                        <Parallax speed={50} translateY={[10, -10]}>
                            <div className={'top'}>
                                <StaticImage className="img-adorno" src="../images/vector-lateral.svg" alt="adorno"/>
                                <h5 className="to" id={"preservingTequila"}><Trans>produced ago</Trans></h5>
                            </div>
                            <p className="parrafo"><Trans>transform us</Trans></p>
                        </Parallax>
                    </div>
                </div>
                {/* <Parallax speed={50} translateY={[50, -50]}>
                    <StaticImage className="textvintage" src="../images/home/texto-back-vintage.png"
                                 alt="texto vintage"/>
                </Parallax> */}
                <Parallax speed={50} translateY={[50, -50]} className="bloque2-prueba">
                    <StaticImage className="agavito" src="../images/home/agavito_1.png" alt="agavito"/>
                    <p className="pbloq2 subto"><Trans>The family</Trans></p>
                    <StaticImage className="agavito" src="../images/home/agavito_2.png" alt="agavito"/>
                </Parallax>

                <div className="nuestro-tequila">
                    <Parallax speed={50} translateY={[25, -25]} className="left">
                        <p className="subtt"><Trans>Our tequila</Trans></p>
                        <div className="parrafo-tequila">
                            <p className="speto">
                                <span><Trans>Discover</Trans></span>
                                <Trans>Discover second</Trans>
                                <StaticImage src="../images/pina-tequila-ilustracion.svg" alt="pina ilustacion"/>
                            </p>
                        </div>
                        <Link to="/our-tequila" className="boton tbutton"><Trans>See button</Trans></Link>
                    </Parallax>

                    <div className="right">
                        <Carousel
                            focusOnSelect
                            infinite
                            arrows={true}
                            showDots={true}
                            draggable
                            shouldResetAutoplay={false}
                            autoPlay={false}
                            centerMode
                            responsive={responsive}>
                            {tequilas.map((data: { node: any; }) => {
                                let tequila = data.node.frontmatter
                                return (
                                    <Link to={tequila.available <= 0 ? '#': tequila.slug} className={`item ${tequila.available <= 0 ? 'soon' : ''}`}>
                                        <GatsbyImage className={'bottle'} alt={tequila.title}
                                                     image={getImage(tequila.bottle_img) as IGatsbyImageData}/>
                                        <p>{tequila.title}
                                            {tequila.available <= 0 &&
                                            <>
                                                <br/>
                                                <span>
                                                    <Trans>Coming soon</Trans>
                                                </span>
                                            </>
                                            }
                                        </p>

                                    </Link>
                                )
                            })}

                        </Carousel>
                    </div>
                </div>

            </section>

            <section className="third-section">

                <div className="info-section">
                    <p className="subtt special-text">Los Altos, Jalisco</p>
                    <div className={'title'}>
                        <StaticImage className="img-adorno" src="../images/vector-lateral.svg" alt="adorno"/>
                        <p className="to text-title">
                            <Trans>Land working</Trans>
                        </p>
                    </div>
                    <p className="parrafo text-info">
                        <Trans>Land description</Trans>
                    </p>
                    <Link to="/our-essence" className="boton tbutton"><Trans>read button</Trans></Link>
                </div>

                <div className="images-section">
                    <Parallax speed={50} translateY={[25, -20]}>
                        <StaticImage className="img-section-1" src="../images/home/jima.jpg" alt="cactus"/>
                    </Parallax>
                    <Parallax speed={20} translateY={[-25, 20]}>
                        <StaticImage className="img-section-2" src="../images/home/image_land.jpg" alt="bebidas"/>
                    </Parallax>
                    <StaticImage className="img-fecha" src="../images/home/vector-fecha.svg" alt="fecha"/>
                </div>

            </section>

            <Parallax speed={50} translateY={[0, 50]} className="fourth-section">

                <StaticImage className="branding-strong" src="../images/home/the-strong-sanctuary.png"
                             alt="logo sanctuary"/>

                <div className="back-to-top" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 0, behavior: `smooth`}) : ''
                }}>
                    <StaticImage className="up-arrow" src="../images/home/back-top.svg" alt="flecha arriba"/>
                    <p className="parrafo text-back">BACK <br/> TO TOP</p>
                </div>

                <p className="parrafo text-registro"><Trans>contact invitation</Trans></p>
                
                {/* <!-- Begin Mailchimp Signup Form --> */}
                {/* <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css"/>
                <div id="mc_embed_signup">
                    <form action="https://chopinimports.us13.list-manage.com/subscribe/post?u=29b5f5714df773b60a9ef2fc6&id=a72c5d4cc2&f_id=009eace2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                        <div id="mc_embed_signup_scroll">
                            <h2>Subscribe</h2>
                            <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                            <div className="mc-field-group">
                                <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span>
                                </label>
                                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required/>
                                <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                            </div>
                            <div id="mce-responses" className="clear">
                                <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                                <div className="response" id="mce-success-response" style={{display:'none'}}></div>
                            </div>
                            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                                <input type="text" name="b_29b5f5714df773b60a9ef2fc6_a72c5d4cc2" tabIndex={-1}/>
                            </div>
                            <div className="clear">
                                <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button">Subscribe</button>
                            </div>
                        </div>
                    </form>
                </div> */}
                {/* <script type='text/javascript' src='https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script> */}
                {/* <script type='text/javascript'>
                    (function($) {
                        window.fnames = new Array();
                        window.ftypes = new Array();
                        fnames[0]='EMAIL';
                        ftypes[0]='email';
                        fnames[1]='FNAME';
                        ftypes[1]='text';
                        fnames[2]='LNAME';
                        ftypes[2]='text';
                        fnames[3]='ADDRESS';
                        ftypes[3]='address';
                        fnames[4]='PHONE';
                        ftypes[4]='phone';
                        fnames[5]='BIRTHDAY';
                        ftypes[5]='birthday';
                    }(jQuery));
                    var $mcj = jQuery.noConflict(true);
                </script> */}
                {/* <!--End mc_embed_signup--> */}

                <form action="https://chopinimports.us13.list-manage.com/subscribe/post?u=29b5f5714df773b60a9ef2fc6&id=a72c5d4cc2&f_id=009eace2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                    <div className="parrafo inscribirme">
                        <input type="email" name="EMAIL" placeholder="Email*" className="email" id="mce-EMAIL" required/>
                        <button type="submit" name="subscribe"><Trans>Sign up</Trans></button>
                    </div>
                </form>

            </Parallax>
        </Layout>
    )
}

export default IndexPage

export function Head() {
    return (
        <title>Santaleza | Inicio</title>
    )
}

export const pageQuery = graphql`
     query TequilasQueryIndex ($language: String!) {
      allMarkdownRemark(sort: {fields: frontmatter___order, order: ASC}) {
        edges {
          node {
            frontmatter {
              slug
              title
              available
              bottle_img { 
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`